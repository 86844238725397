import isEmpty from 'lodash/isEmpty';
import {useRef, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {cnb} from 'cnbuilder';
import {useL10nDateTimeContext} from '@teladoc/pulse/ui/Context/l10n/DateTime';
import HorizontalRule from '@teladoc/pulse/ui/HorizontalRule';
import IconCheckCircleDefault from '@teladoc/pulse/icons/check-circle-default.svg';
import Button from '@teladoc/pulse/ui/Button';
import Form from '@teladoc/pulse/ui/Form';
import parseISO from 'date-fns/parseISO';
import {utcToZonedTime} from 'date-fns-tz';
import addMinutes from 'date-fns/addMinutes';
import Arg from '@livongo/arg';
import useTransLoader from 'i18n/use-trans-loader';
import CommonUtils from '../utils/common-utils';
import Card from '../common/Card';
import ConfirmationDetails from '../ConfirmationPage/ConfirmationDetails';
import CancelModal from '../ConfirmationPage/cancelmodal/CancelModal';
import TimeTap from '../utils/TimeTap';
import SessionDateUtil from '../utils/session-date-util';
import MixpanelUtils from '../common/mix-panel';
import Loader from '../common/loader/Loader';
import css2 from '../SchedulingForm/SchedulingFormV2.scss';
import css3 from '../ConfirmationPage/view/SmallView.scss';
import css from './SessionDetails.scss';

const SessionDetails = () => {
    const appointmentData = useSelector(
        state => state.scheduler.appointmentData
    );
    const calendarId = Arg('calendarId');
    const {intlFormat} = useL10nDateTimeContext();
    const {t} = useTransLoader('confirmation');
    const {
        type,
        datetime,
        startDate,
        endDate,
        duration,
        timezone,
    } = appointmentData;

    const {token} = useSelector(state => state.userData.auth);
    const [isLoading, setIsLoading] = useState(true);
    const {sharedCoaching} = useSelector(state => state.scheduler);
    const isSharedCoaching = !isEmpty(sharedCoaching);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [coachingSession, setCoachingSession] = useState(null);
    const [apiSessionToken, setApiSessionToken] = useState(token);
    const [successfullyCancelled, setSuccessfullyCancelled] = useState(false);
    const isMentalHealth = Arg('referrer')?.includes('mystrength');
    const isWebSDK = Arg('oneAppSdk');
    const isMobile = Arg('mobile');
    const rootClass = cnb(css.root);
    const mobile = true;
    const callType = SessionDateUtil.getCallTypeV2(
        coachingSession?.reason?.reasonDesc
    )
        .toLocaleLowerCase()
        .replace(' ', '-');

    const eventName = isSharedCoaching
        ? 'Shared Coaching'
        : isMentalHealth
        ? type.replace('[TEST]', '').replace('(INT)', '').trim()
        : type;
    const utcStartDate = isSharedCoaching
        ? new Date(sharedCoaching?.preferredTimeWindow[0])
        : isMentalHealth
        ? parseISO(datetime)
        : startDate;
    const utcEndDate = isSharedCoaching
        ? new Date(sharedCoaching?.preferredTimeWindow[1])
        : isMentalHealth
        ? addMinutes(utcStartDate, parseInt(duration || 0, 10))
        : endDate;
    const localStartDate = utcStartDate
        ? utcToZonedTime(utcStartDate, timezone || 'America/Chicago')
        : null;
    const localEndDate = utcStartDate
        ? utcToZonedTime(utcEndDate, timezone || 'America/Chicago')
        : null;
    const contentRef = useRef();
    const showCancel = () => {
        setShowCancelModal(!showCancelModal);

        if (showCancelModal) {
            MixpanelUtils.track({
                event: 'coaching.next.session.cancel.clicked',
                properties: {
                    // eslint-disable-next-line camelcase
                    coaching_session_id: calendarId ?? '',
                },
            });
        }
    };
    const setCancelSucces = () => {
        setSuccessfullyCancelled(true);
        contentRef?.current?.focus();
    };

    const openOutLookCalender = () => {
        if (localStartDate) {
            CommonUtils.openOutLookCalender({
                end: localEndDate,
                start: localStartDate,
                name: eventName,
                alreadyFormtted: false,
                mwUrl: process.env.MIDDLEWARE_URL,
            });
        } else {
            CommonUtils.openOutLookCalender({
                start: coachingSession?.clientStartDateTimeUTC,
                end: coachingSession?.clientEndDateTimeUTC,
                name: coachingSession?.reason?.reasonDesc,
                alreadyFormtted: true,
                mwUrl: process.env.MIDDLEWARE_URL,
            });
        }
        MixpanelUtils.track({
            event: 'coaching.scheduling.add.to.calendar.clicked',
            properties: {
                type: 'ical_outlook',
                source: 'scheduling',
            },
        });
    };

    const openGoogleCalendar = () => {
        if (localStartDate) {
            CommonUtils.openGoogleCalendar({
                start: localStartDate,
                end: localEndDate,
                name: eventName,
            });
        } else {
            CommonUtils.openGoogleCalendar({
                start: coachingSession?.clientStartDateTimeUTC,
                end: coachingSession?.clientEndDateTimeUTC,
                name: coachingSession?.reason?.reasonDesc,
            });
        }
        MixpanelUtils.track({
            event: 'coaching.scheduling.add.to.calendar.clicked',
            properties: {
                type: 'google',
                source: 'scheduling',
            },
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        if (isWebSDK) {
            const sendHeightToParent = () => {
                const message = {height: document.body.scrollHeight};

                window.top.postMessage(message, '*');
            };

            if (window.ResizeObserver) {
                const resizeObserver = new ResizeObserver(entries =>
                    entries.forEach(entry => sendHeightToParent())
                );

                resizeObserver.observe(document.body);
            }
        }
    }, [isWebSDK]);

    useEffect(() => {
        const getCoachSession = async () => {
            try {
                const data = await TimeTap.getCoachingSession(
                    token,
                    calendarId
                );

                setApiSessionToken(token);
                setCoachingSession(data);
                setIsLoading(false);
            } catch {
                setIsLoading(false);
                // do nothing
            }
        };

        if (calendarId && token) {
            getCoachSession();
        }
    }, [calendarId, token]);

    useEffect(() => {
        if (!isMobile) {
            MixpanelUtils.track({
                event: 'coaching.scheduling.confirmation.viewed',
            });
        }

        if (isMobile) {
            MixpanelUtils.track({
                event: 'coaching.next.session.viewed',
            });
        }
    }, [isMobile]);

    useEffect(() => {
        document.title = `${t('sessionScheduleDetails')} | Teladoc Health`;
    }, [t]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <div className={css2.root}>
                <Form className={css2.innerFrame}>
                    <div className={css.outerFrame}>
                        {showCancelModal && calendarId && (
                            <CancelModal
                                setShowCancelModal={showCancel}
                                calendarId={calendarId}
                                apiSessionToken={apiSessionToken}
                                setSuccessfullyCancelled={setCancelSucces}
                                isMobile={mobile}
                            />
                        )}
                        <Card>
                            <div className={rootClass}>
                                {((!isLoading &&
                                    coachingSession?.status !== 'OPEN') ||
                                    successfullyCancelled) && (
                                    <div
                                        className={css.tipWrapperSessionDetails}
                                        ref={contentRef}
                                        tabIndex={-1}
                                    >
                                        <span className={css.iconInfo}>
                                            <IconCheckCircleDefault
                                                className={css.iconFillSuccess}
                                            />
                                        </span>
                                        <div className={css.description}>
                                            <span className={css.tipTitle}>
                                                {t('cancelConfirmation')}
                                            </span>
                                            <br />
                                            <span className={css.tipTitle2}>
                                                {t('scheduleAnyTime')}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <div>
                                    <p>
                                        <h1
                                            tabIndex={1}
                                            className={
                                                isMobile
                                                    ? !successfullyCancelled
                                                        ? css.newSessionDetailsTitleMobileActive
                                                        : css.newSessionDetailsTitleMobile
                                                    : css.newSessionDetailsTitle
                                            }
                                        >
                                            {t('sessionScheduleDetails')}
                                        </h1>
                                    </p>
                                    <div
                                        className={
                                            isMobile ? css.callTextMobile : ''
                                        }
                                    >
                                        <div className={css3.root}>
                                            <section className={css3.section}>
                                                <div
                                                    className={
                                                        !successfullyCancelled
                                                            ? css.descriptionStatusActive
                                                            : css.descriptionStatus
                                                    }
                                                >
                                                    <span>
                                                        {t(
                                                            'sessionScheduledCoach'
                                                        )}
                                                    </span>
                                                </div>
                                                <div
                                                    className={
                                                        !successfullyCancelled
                                                            ? css.statusCodeActive
                                                            : css.statusCode
                                                    }
                                                >
                                                    <span>
                                                        {SessionDateUtil.getCoachName(
                                                            coachingSession,
                                                            appointmentData
                                                        )}
                                                    </span>
                                                </div>
                                            </section>
                                            <HorizontalRule
                                                spaceTop={50}
                                                spaceBottom={50}
                                            />
                                            <section className={css3.section}>
                                                <div
                                                    className={
                                                        !successfullyCancelled
                                                            ? css.descriptionStatusActive
                                                            : css.descriptionStatus
                                                    }
                                                >
                                                    <span>{t('date')}</span>
                                                </div>
                                                <div
                                                    className={
                                                        !successfullyCancelled
                                                            ? css.statusCodeActive
                                                            : css.statusCode
                                                    }
                                                >
                                                    <span>
                                                        {SessionDateUtil.getScheduledDate(
                                                            coachingSession,
                                                            appointmentData,
                                                            2,
                                                            intlFormat
                                                        )}
                                                    </span>
                                                </div>
                                            </section>
                                            <HorizontalRule
                                                spaceTop={50}
                                                spaceBottom={50}
                                            />
                                            <section className={css3.section}>
                                                <div
                                                    className={
                                                        !successfullyCancelled
                                                            ? css.descriptionStatusActive
                                                            : css.descriptionStatus
                                                    }
                                                >
                                                    {t('time')}
                                                </div>
                                                <div
                                                    className={
                                                        !successfullyCancelled
                                                            ? css.statusCodeActive
                                                            : css.statusCode
                                                    }
                                                >
                                                    {SessionDateUtil.getScheduledTime(
                                                        coachingSession,
                                                        appointmentData,
                                                        intlFormat
                                                    )}
                                                </div>
                                            </section>
                                            <HorizontalRule
                                                spaceTop={15}
                                                spaceBottom={15}
                                            />
                                            <section className={css3.section}>
                                                <div
                                                    className={
                                                        !successfullyCancelled
                                                            ? css.descriptionStatusActive
                                                            : css.descriptionStatus
                                                    }
                                                >
                                                    {t('sessionScheduledType')}
                                                </div>
                                                <div
                                                    className={
                                                        !successfullyCancelled
                                                            ? css.statusCodeActive
                                                            : css.statusCode
                                                    }
                                                >
                                                    {callType === 'initial' ||
                                                    callType === 'follow-up'
                                                        ? `${callType} call`
                                                        : callType}
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                    <div className={css.topspace}>
                                        {!isLoading &&
                                            coachingSession?.status ===
                                                'OPEN' &&
                                            !successfullyCancelled && (
                                                <div className={css.newDetail}>
                                                    <Button
                                                        type="submit"
                                                        variant="secondary"
                                                        className={
                                                            css.mobileCancelButton
                                                        }
                                                        role="link"
                                                        onClick={showCancel}
                                                    >
                                                        {t('cancelSession')}
                                                    </Button>
                                                </div>
                                            )}
                                    </div>
                                </div>
                                {!isLoading &&
                                    coachingSession?.status === 'OPEN' &&
                                    !successfullyCancelled && (
                                        <div className={css.panel}>
                                            {isMobile && (
                                                <>
                                                    <div
                                                        className={
                                                            css.howToPrepare
                                                        }
                                                    >
                                                        How to prepare
                                                    </div>
                                                    <ConfirmationDetails />
                                                </>
                                            )}
                                        </div>
                                    )}
                            </div>
                        </Card>
                        {!isLoading &&
                            coachingSession?.status === 'OPEN' &&
                            !successfullyCancelled && (
                                <div>
                                    <div className={css.outerAction}>
                                        <div className={css.preferal}>
                                            <p className={css.topspace}>
                                                {t('calendarBottom')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className={css.outerAction}>
                                        <div
                                            className={
                                                css.mobileCalendarButtons
                                            }
                                        >
                                            <Button
                                                type="submit"
                                                variant="secondary"
                                                className={css.appleButton}
                                                role="link"
                                                onClick={() => {
                                                    openOutLookCalender();
                                                }}
                                            >
                                                iCAL/Outlook
                                            </Button>
                                            <Button
                                                type="submit"
                                                variant="secondary"
                                                className={css.googleButton}
                                                role="link"
                                                onClick={() => {
                                                    openGoogleCalendar();
                                                }}
                                            >
                                                Google
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default SessionDetails;
