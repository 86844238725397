import PropTypes from 'prop-types';
import HorizontalRule from '@teladoc/pulse/ui/HorizontalRule';
import {useL10nDateTimeContext} from '@teladoc/pulse/ui/Context/l10n/DateTime';
import useTransLoader from 'i18n/use-trans-loader';
import SessionDateUtil from '../../utils/session-date-util';
import CommonUtils from '../../utils/common-utils';
import css from './SmallView.scss';

const SmallView = ({appointmentData, coachingSession}) => {
    const {t} = useTransLoader('confirmation');
    const {intlFormat} = useL10nDateTimeContext();
    let type = appointmentData.type;

    if (!type) {
        type = coachingSession?.reason?.reasonDesc;
    }

    const callType = SessionDateUtil.getCallTypeV2(type)
        .toLocaleLowerCase()
        .replace(' ', '-');

    return (
        <div className={css.root}>
            <section className={css.section}>
                <div className={css.descriptionStatus}>
                    <span>{t('sessionScheduledCoach')}</span>
                </div>
                <div className={css.statusCode}>
                    <span>
                        {SessionDateUtil.getCoachName(
                            coachingSession,
                            appointmentData
                        )}
                    </span>
                </div>
            </section>
            <HorizontalRule spaceTop={50} spaceBottom={50} />
            <section className={css.section}>
                <div className={css.descriptionStatus}>
                    <span>{t('date')}</span>
                </div>
                <div className={css.statusCode}>
                    <span>
                        {SessionDateUtil.getScheduledDate(
                            coachingSession,
                            appointmentData,
                            2,
                            intlFormat
                        )}
                    </span>
                </div>
            </section>
            <HorizontalRule spaceTop={50} spaceBottom={50} />
            <section className={css.section}>
                <div className={css.descriptionStatus}>{t('time')}</div>
                <div className={css.statusCode}>
                    {SessionDateUtil.getScheduledTime(
                        coachingSession,
                        appointmentData,
                        intlFormat
                    )}
                </div>
            </section>
            <HorizontalRule spaceTop={15} spaceBottom={15} />
            <section className={css.section}>
                <div className={css.descriptionStatus}>
                    {t('sessionScheduledType')}
                </div>
                <div className={css.statusCode}>
                    {CommonUtils.getFormattedType(callType)}
                </div>
            </section>
            <HorizontalRule spaceTop={15} spaceBottom={15} />
            <section className={css.section}>
                <div className={css.descriptionStatus}>
                    {t('sessionScheduledMethod')}
                </div>
                <div className={css.statusCode}>
                    <div>
                        <div>
                            {SessionDateUtil.getCallMethod(
                                appointmentData,
                                coachingSession
                            )}
                        </div>
                        <div>{SessionDateUtil.getCallMethodDetails()}</div>
                    </div>
                </div>
            </section>
        </div>
    );
};

SmallView.propTypes = {
    appointmentData: PropTypes.object,
    coachingSession: PropTypes.object,
};

export default SmallView;
